.NyElevContainter {
  width: 90%;
  display: flex;
  justify-content: center;
  margin: auto;
  margin-top: 30px;
  flex-direction: column;
  align-items: center;
}
.NyElevTextContainter {
  background-color: #c7e296;
  text-align: center;
  color: #33490a;
  padding-top: 30px;
  padding-bottom: 60px;
  padding-left: 10%;
  padding-right: 10%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  p {
    white-space: pre-line;
  }
}
.NyElevSignUpYearSelecter {
  display: flex;
  align-items: center;
  p {
    margin: none;
  }
}

.NyElevSignUpContainter {
  background-color: #e5fcbc;
  position: relative;
  bottom: 40px;
  width: 50%;
  color: #33490a;
  padding: 30px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    padding-right: 10px;
  }
}

.AnsøgButton {
  background-color: white;
  color: #33490a;
  border-radius: 50px;
  border: none;
  padding-left: 30px;
  padding-right: 30px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.AnsøgButton:hover {
  transform: scale(1.2);
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25);
}

select {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-family: inherit;
  font-size: inherit;
  font-weight: bolder;
  color: #33490a;
  background-color: white;
  border: none;
  display: inline-block;
  line-height: 1.5em;
  padding: 0.5em 3em 0.5em 1em;

  /* reset */

  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: linear-gradient(45deg, transparent 50%, #ff8e09 50%),
    linear-gradient(135deg, #ff8e09 50%, transparent 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
}

select:focus {
  background-image: linear-gradient(45deg, #ff8e09 50%, transparent 50%),
    linear-gradient(135deg, transparent 50%, #ff8e09 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position: calc(100% - 15px) 1em, calc(100% - 20px) 1em,
    calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
  outline: 0;
}

@media only screen and (max-width: 600px) {
  .NyElevSignUpYearSelecter {
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
  select {
    margin-bottom: 20px;
  }
}
