.SoMeIconRow{
    display: flex;
    justify-content: space-between;
    width: 20vw;
}

.SoMeIcon{
    height: 4vw;
    width: 4vw;
    color: #93B15C;
}
.SoMeIcon:hover{
    color: #FF8E09;
}

@media only screen and (max-width: 700px) {
    .SoMeIconRow{
        max-width: 50vw;
        min-width: 45vw;
    }
    .SoMeIcon{
        height: 8vw;
        width: 8vw;
    }
    
}