.BlivElevKnap {
  z-index: 1000000;
  font-size: 20px;
  font-weight: bold;
  background-color: white;
  color: rgba(255, 142, 9, 1);
  border-radius: 50px;
  border: none;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 30px;
  padding-right: 30px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: fixed;
  bottom: 15px;
  right: 15px;
}

.BlivElevKnap:hover {
  z-index: 1000000;
  transform: scale(1.2);
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25);
  cursor: pointer;
}
