.navBarContainer {
  width: 100%;
  height: 117.5px;
  background-color: #657e36;
  display: flex;
  font-family: "SmashingW00-Regular";
  z-index: 10;
  transition: all 1s ease;
}

.navBarContainerShown {
  font-family: "SmashingW00-Regular";
  width: 100%;
  height: 117.5px;
  display: flex;
  align-items: center;
  background-color: #657e36;
  overflow: hidden;
  position: fixed;
  transition: all 1s ease;
  z-index: 10;
}

.navBarLogoContainer {
  width: 50vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 30px;
}

.navBarLogoTitle {
  font-size: 36px;
  margin-top: 0;
  margin-bottom: 0;
}

.navBarLinkContainer {
  width: 75vw;
  height: 100%;
  display: flex;
  font-size: 1.3em;
}

.navBarLinkContainerItemContainer {
  flex: 1;
}

.navBarLinkContainerItem {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  width: 100%;
  height: 100%;

  color: white;
}

.navBarLinkContainerItemActive {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  width: 100%;
  height: 100%;
  background-color: #93b15c;
  color: white;
}

.navBarLinkContainerItem:hover {
  background-color: #93b15c;
}

.navBarLinkContainerItemActive:hover {
  background-color: #93b15c;
}
.navIcon {
  display: none;
}

@media screen and (max-width: 960px) {
  .navBarLogoContainer {
    margin-left: 5%;
    padding-top: 5px;
    padding-bottom: 1px;
  }

  .navBarLogoTitle {
    font-size: 32px;
    margin-top: 0;
    margin-bottom: 0;
  }

  .navBarContainerShown {
    font-family: "SmashingW00-Regular";
    width: 100%;
    height: 117.5px;
    display: flex;
    align-items: center;
    background-color: #657e36;
    position: fixed;
    transition: all 1s ease;
    overflow: visible;
    z-index: 1000000;
    .navBarLinkContainerShown {
      display: flex;
      flex-direction: column;
      width: 100%;
      border-top: 4px solid #fff;
      position: absolute;
      top: 100%;
      opacity: 1;
      transition: all 0.5s ease;
      background: #657e36;
      left: 0px;
    }
  }

  .navBarContainer {
    overflow: visible;
    position: fixed;
    z-index: 1000000;
  }

  .navIcon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .navBarLinkContainerItem {
    padding: 1.5rem;
    width: 100%;
    display: table;
  }

  .navBarLinkContainerItemActive {
    padding: 1.5rem;
    width: 100%;
    display: table;
  }

  .navBarLinkContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-top: 4px solid #fff;
    position: absolute;
    top: 10%;
    left: -150%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .navBarLinkContainerShown {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-top: 4px solid #fff;
    position: absolute;
    top: 100%;
    opacity: 1;
    transition: all 0.5s ease;
    background: #657e36;
    left: 0px;
  }
}
