body {
  margin: 0;
  font-family: "Work Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p {
  line-height: 1.5em;
}

a {
  text-decoration: none;
  color: #ff8e09;
}
a:hover {
  color: #ffb560;
}

h1,
h2,
h3,
h4 {
  font-family: "SmashingW00-Regular";
}

html {
  scroll-behavior: smooth;
}

@font-face {
  font-family: "SmashingW00-Regular";
  src: url("fonts/e3b2b4c0733367de3a3cb5b605116ab1.woff2") format("woff2")
}
