.NyISkole {
    display: flex;
    flex-direction: column;
    align-items: center;
    .SFO{
        max-width: 90%;
        background-color: #D8F1AB;
        color: #33490A;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        .SFOText{
            padding: 40px;
            display: flex;
        }
        .endText{
            padding: 40px;
        }
        
    }
}

@media only screen and (max-width: 1200px) {
    .NyISkole {
        .SFO{
            .SFOText{
                flex-direction: column;
            }
            
        }
    }
}