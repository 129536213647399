.Foraeldre {
  color: black;
}
.button {
  background-color: #7c9848;
  border-radius: 50px;
  border: none;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 30px;
  padding-right: 30px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-top: 20px;
}
.button:hover {
  transform: scale(1.2);
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25);
  cursor: pointer;
}
.headerButtons {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.calender {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  img {
    max-width: 45%;
  }
  div {
    width: 45%;
    margin: 0 !important;
  }
}
.pageContent {
  padding-top: 40px;
  width: 90%;
  margin: auto;
  .box {
    color: #33490a;
    background-color: #d8f1ab;
    padding: 40px;
    margin-bottom: 40px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    p {
      white-space: pre-line;
    }
    .cards {
      width: 100%;
      display: flex;
      justify-content: space-evenly;
    }
    .moderation {
      h5 {
        font-size: 20px;
      }
      text-align: center;
      padding: 20px;
    }
    .infoCard {
      max-width: 30vw;
      flex: 1;
      text-align: center;
      padding: 40px;
      background: #e5fcbc;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      p {
        white-space: pre-line;
      }
    }
  }
}

@media only screen and (max-width: 700px) {
  .cards {
    flex-direction: column;
    align-items: center;
  }
  .infoCard {
    margin-top: 20px;
  }
  .calender {
    flex-direction: column;
    align-items: center;
    img {
      min-width: 85%;
    }
    div {
      width: 85%;
    }
  }
}
