.FaciliteterImage {
  border-width: 0;
  position: relative;
  width: 33.4vw;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative;
  }
}
.overlay {
  display: none;
}
.FaciliteterImage:hover + .overlay {
  z-index: 1;
  display: flex;
  background-color: rgba($color: #00000024, $alpha: 0.6);
  color: white;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  text-align: center;
  position: absolute;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.FaciliteterImageHover {
  border-width: 0;
  position: relative;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative;
  }
  .overlay {
    width: 100%;
    height: 100%;
    color: white;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    background-color: rgba($color: #00000024, $alpha: 0.6);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .overlayHeader1 {
      letter-spacing: 0.05em;
    }
    .overlayHeader2 {
      letter-spacing: 0.05em;
    }
  }
}

@media screen and (max-width: 960px) {
  .FaciliteterImageHover {
    border-width: 0;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: relative;
    }
    .overlay {
      display: none;
    }
  }
}
