.Header {
  display: flex;
  color: white;
  flex-direction: column;
  width: 100%;
  margin-top: 117.5px;
  .headerImage {
    width: 100%;
    height: 80vh;
    object-fit: cover;
  }
  .headerText {
    font-size: 5vw;
    max-width: 55vw;
    text-align: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    letter-spacing: 0.05em;
  }
  .headerTextBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #33490a;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
    p {
      max-width: 60vw;
    }
  }
}
.Header2 {
  display: flex;
  color: white;
  flex-direction: column;
  width: 100%;
  .headerImage {
    margin-top: 117.5px;
    width: 100%;
    height: 80vh;
    object-fit: cover;
  }
  .headerText {
    font-size: 5vw;
    max-width: 55vw;
    text-align: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }
  .headerTextBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #33490a;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
    p {
      max-width: 60vw;
    }
  }
}

@media only screen and (max-width: 700px) {
  .Header {
    .headerText {
      min-width: 80vw;
      font-size: 7vw;
      z-index: 1;
    }
    .headerTextBox {
      p,
      h3 {
        max-width: 80vw;
      }
    }
  }
}
