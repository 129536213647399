.Faciliteter {
  width: 100%;
  color: #33490a;
  background-color: #d8f1ab;
  text-align: center;
  padding-top: 20px;

  .faciliteterText {
    margin: auto;
    max-width: 80%;
    padding-bottom: 20px;
  }
  .faciliteterImages {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }
  .text {
    white-space: pre-line;
  }
}
