.Forside{
    display: flex;
    flex-direction: column;
    align-items: center;
    .historie{
        display: flex;
        .historieInfo, .kastanjenBillede{
            flex: 1;
        }
        .historieInfo{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            padding: 20px;
        }
        .kastanjenBillede{
            width: 50%;
            height: auto;
            object-fit: cover;
            position: relative;
        }
        h3, p{
            color: rgba(51, 73, 10, 1) !important;
        }
    }
    .video{
        width: 80%;
        height: auto;
        padding: auto;
    }
    .videoWrap{
        display: flex;
        justify-content: center;
        padding-top: 40px;
        padding-bottom: 40px;
    }
    .soMe{
        padding-top: 20px;
        padding-bottom: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        color: #33490A;

    }
}

@media only screen and (max-width: 700px) {
    .historie{
        flex-direction: column;
        .kastanjenBillede{
            width: 100% !important;
        }
    }
}
