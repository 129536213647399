.InfoBox {
  display: flex;
  align-items: center;
  max-width: 90%;
  padding-top: 20px;
  padding-bottom: 20px;

  .info {
    padding: 20px;
    height: 50vh;
    background: #d8f1ab;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    color: rgba(51, 73, 10, 1);
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .image {
    max-width: 45%;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .text {
    white-space: pre-line;
  }
}
.reverse {
  flex-direction: row-reverse;
}

@media screen and (max-width: 750px) {
  //Something to make it mobile friendly
  .InfoBox {
    flex-direction: column-reverse;
    height: auto;
    .info{
      height: auto;
    }
    .image {
      flex: none;
      max-width: 100%;
      height: 500px;
      object-fit: cover;
    }
  }
}
