.Footer {
  background-color: #33490a;
  display: flex;
  .logoText {
    margin: 1px;
    font-size: 6vw;
    color: #93b15c;
    line-height: 1em;
    letter-spacing: 0.05em;
  }
}
.section1,
.section2 {
  flex: 1;
}
.section1 {
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

button {
  all: unset;
}

.infoLink {
  color: #93b15c;
  background-color: #33490a;
  border-color: #93b15c;
  border-style: solid;
  border-width: 2px;
  padding: 10px 2.5vw;
  border-radius: 50px;
  font-size: 1vw;
}
.infoLink:hover {
  color: #ff8e09;
  border-color: #ff8e09;
}
.linkButtons {
  width: 80%;
  display: flex;
  justify-content: space-between;
}
.LedigeStillinger {
  color: #93b15c;
}
.kontorInfoText {
  color: #93b15c;
  font-size: 2vw;
}
.kontakt {
  color: #33490a;
  background-color: #93b15c;
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.kontorInfo {
  display: flex;
}
.kontorInfoText {
  padding-right: 30px;
  white-space: pre-line;
}

.kontaktTitle {
  flex: 1;
  text-align: center;
  font-size: 3vw;
  p {
    margin: 0px !important;
  }
}
.kontaktInfo {
  flex: 2;
  font-size: 2vw;
  p {
    margin: 0px !important;
  }
}

.kontakt,
.kontorInfo {
  a {
    color: inherit;
  }
  a:hover {
    color: #ff8e09;
  }
}

@media only screen and (max-width: 700px) {
  .Footer {
    flex-direction: column;
  }
  .section1 {
    align-items: center;
    padding-bottom: 0;
  }
  .logo {
    .logoText {
      font-size: 12vw;
    }
    padding-bottom: 20px;
  }
  .linkButtons {
    padding-top: 20px;
    .infoLink {
      font-size: 2vw;
      border-width: 1px;
      min-width: 15vw;
      text-align: center;
    }
  }
  .kontakt {
    .kontaktTitle {
      font-size: 6vw;
      margin: 20px;
    }
    .kontaktInfo {
      font-size: 4vw;
      margin: 10px;
    }
  }
  .kontorInfo {
    justify-content: space-evenly;
    .kontorInfoText {
      font-size: 4vw;
    }
  }
  .LedigeStillinger {
    text-align: center;
    padding-bottom: 60px;
  }
}
